import { template as template_5fd6a12d39154345b7b574315e8532bf } from "@ember/template-compiler";
const FKText = template_5fd6a12d39154345b7b574315e8532bf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
