import { template as template_d97023d1047141da93ea7d23c0edc6f7 } from "@ember/template-compiler";
const FKLabel = template_d97023d1047141da93ea7d23c0edc6f7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
