import { template as template_be7c2b60ae544811b9a85dec0f0f1f3c } from "@ember/template-compiler";
const FKControlMenuContainer = template_be7c2b60ae544811b9a85dec0f0f1f3c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
