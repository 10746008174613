import { template as template_d87123b9c0c948bcac319003a8ed8c84 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d87123b9c0c948bcac319003a8ed8c84(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
